<div class="flex flex-col sm:ml-5">
  <div class="grid grid-cols-1 justify-items-start">
    <div
      [ngClass]="{
        'w-1/2': !bypassHalfWidth
      }"
    >
      <tilled-form-heading class="py-2">{{ title }}</tilled-form-heading>
      <tilled-paragraph-p3 *ngIf="description" [secondary]="true">{{ description }}</tilled-paragraph-p3>
    </div>
    <div class="flex w-full flex-col items-start py-6" [ngClass]="{ 'forConsole px-12': forConsole }">
      <ng-content></ng-content>
    </div>
    <div
      [attr.style]="
        'width: ' +
        (!continueButtonText.includes('Application')
          ? tilledInputWidth
          : title === 'Welcome'
            ? getElementWidth('welcome-merchant-step [data-test-id=\'welcome-step\']')
            : '100%')
      "
      class="btnGroup flex flex-row space-x-4 pb-5"
    >
      <div *ngIf="backButton" class="grow">
        <tilled-button
          class="backButton"
          [styles]="'flex-1 w-full'"
          data-test-id="merchant-app-backBtn"
          bgColor="tilled-neutral-200"
          [whiteText]="false"
          [secondary]="true"
          [rounded]="true"
          buttonText="Back"
          (buttonClick)="onButtonClick('back')"
        ></tilled-button>
      </div>
      <div *ngIf="continueButton" class="grow">
        <tilled-button
          [styles]="'flex-1 w-full'"
          class="continueButton"
          data-test-id="merchant-app-saveBtn"
          bgColor="tilled-primary"
          [rounded]="true"
          [disabled]="disableContinueButton"
          [buttonText]="continueButtonText"
          (buttonClick)="onButtonClick('continue')"
          [suffixIcon]="continueButtonIcon"
        ></tilled-button>
      </div>
    </div>
  </div>
</div>
