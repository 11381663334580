<div class="top-of-form"></div>
<merchant-app-card
  [title]="!forConsole ? 'Payment Processing Volume' : null"
  [description]="!forConsole ? 'Enter your current or projected processing volumes' : null"
  [backButton]="!forConsole"
  (backClick)="onBackClicked($event)"
  (continueClick)="onContinueClicked($event)"
  [continueButton]="!forConsole && !(disabled$ | async)"
>
  <form data-test-id="processing-volumes-step" class="w-full space-y-4" [formGroup]="processingVolumesForm">
    <div class="items-left flex flex-col pt-2.5" [ngClass]="!forConsole ? 'w-1/2' : 'w-full'">
      <tilled-heading-h4>Processing Volumes and History</tilled-heading-h4>
      <tilled-paragraph-p3 [secondary]="true" class="pt-3">
        Estimate the amount of money your business handles in credit card and bank to bank payments.
      </tilled-paragraph-p3>
    </div>
    <div class="flex w-full flex-col">
      <tilled-input
        [merchantApp]="true"
        data-test-id="average-monthly-transactions"
        name="averageMonthlyTransactions"
        mask="separator.0"
        label="Number of Transactions per month"
        tilledLabel="true"
        hint="Estimate the average number of credit card or bank to bank transactions you make in a month."
        [errors]="
          processingVolumesForm.get('averageMonthlyTransactions').hasError('min') &&
          processingVolumesForm.get('averageMonthlyTransactions').touched
        "
        errorMessage="Must be greater than $0"
      ></tilled-input>
    </div>
    <div class="flex flex-col">
      <tilled-input
        [merchantApp]="true"
        data-test-id="average-monthly-volume"
        name="averageMonthlyVolume"
        showCurrency="'true'"
        mask="separator.0"
        label="Monthly Processing Volume"
        tilledLabel="true"
        hint="Estimate the average amount you make in a month from credit card and bank to bank sales."
        [errors]="
          processingVolumesForm.get('averageMonthlyVolume').touched &&
          (processingVolumesForm.get('averageMonthlyVolume').hasError('max') ||
            processingVolumesForm.get('averageMonthlyVolume').hasError('min'))
        "
        [errorMessage]="
          processingVolumesForm.get('averageMonthlyVolume').hasError('min')
            ? 'Must be greater than $' + monthMin
            : processingVolumesForm.get('averageMonthlyVolume').hasError('max')
              ? 'Must be less than $' + monthMax
              : 'Test'
        "
      ></tilled-input>
    </div>
    <div class="flex flex-col">
      <div class="pb-2.5 pt-4" [ngClass]="!forConsole ? 'w-1/2' : 'w-full'">
        <tilled-label-l1 secondary="true">Previous payment processor</tilled-label-l1>
      </div>
      <tilled-select
        [merchantApp]="true"
        data-test-id="previous-processor"
        [multiple]="false"
        [options]="previousProcessorMap"
        (selectionChange)="onPreviousProcessorChange()"
        [controlName]="processingVolumesForm.get('previousProcessor')"
        [placeholder]="'Select your previous payment processor'"
        [classOverride]="forConsole ? 'w-full' : ''"
      ></tilled-select>
    </div>
    <!-- Processor Details -->
    <div *ngIf="showPreviousProcessorOther$ | async" class="flex w-full flex-col">
      <tilled-input
        [merchantApp]="true"
        data-test-id="previous-processor-other"
        name="previousProcessorOther"
        label="Please enter the name of your last payment processor"
        tilledLabel="true"
      ></tilled-input>
    </div>
    <div class="flex flex-col">
      <div class="pb-2.5 pt-4" [ngClass]="!forConsole ? 'w-1/2' : 'w-full'">
        <tilled-label-l1 secondary="true"
          >On average, how long after paying will your customers typically receive your goods or
          services?</tilled-label-l1
        >
      </div>
      <tilled-select
        [merchantApp]="true"
        data-test-id="days-prior-to-shipping"
        [multiple]="false"
        [classOverride]="forConsole ? 'w-full' : ''"
        [options]="shippingTimesTypesMap"
        [controlName]="processingVolumesForm.get('daysPriorToShipping')"
        [placeholder]="'Choose One'"
        [hint]="'On average, how long after paying will your customers typically receive your goods or services?'"
      ></tilled-select>
    </div>
    <!-- Transaction Percentages -->
    <div [formGroup]="processingVolumesForm.get('percentsFormGroup')">
      <div class="items-left flex flex-col pt-4">
        <tilled-heading-h4>Checkout Methods</tilled-heading-h4>
        <tilled-paragraph-p3 [secondary]="true" class="pt-3" [ngClass]="!forConsole ? 'w-5/12' : 'w-full'">
          What percent of your total transactions are processed through each of these checkout methods?
        </tilled-paragraph-p3>
        <div class="w-full pb-4">
          <tilled-paragraph-p3
            [secondaryText]="true"
            [warn]="true"
            *ngIf="
              processingVolumesForm.get('percentsFormGroup').errors &&
              processingVolumesForm.get('percentsFormGroup').errors.percentagesSumTo100
            "
          >
            Must add up to 100%. Accept whole numbers only.
          </tilled-paragraph-p3>
        </div>
        <tilled-input
          [merchantApp]="true"
          data-test-id="card-present"
          name="cardPresent"
          groupName="percentsFormGroup"
          [sentenceCaseTitle]="false"
          label="In-person/Card-present"
          tilledLabel="true"
          minValue="0"
          maxValue="100"
          mask="percent"
          placeholder="Enter %"
          [showPercent]="true"
          [hint]="'You take payments in person, using a card reader.'"
        ></tilled-input>
        <tilled-input
          [merchantApp]="true"
          data-test-id="e-commerce"
          name="eCommerce"
          groupName="percentsFormGroup"
          [sentenceCaseTitle]="false"
          label="Website/E-commerce"
          tilledLabel="true"
          minValue="0"
          maxValue="100"
          mask="percent"
          placeholder="Enter %"
          [showPercent]="true"
          [hint]="'You take payments through a website.'"
        ></tilled-input>
        <tilled-input
          [merchantApp]="true"
          data-test-id="card-not-present"
          name="cardNotPresent"
          groupName="percentsFormGroup"
          label="Over the phone"
          tilledLabel="true"
          minValue="0"
          maxValue="100"
          mask="percent"
          placeholder="Enter %"
          [showPercent]="true"
          [hint]="'Your customers call you and you enter their payment details while on the phone.'"
        ></tilled-input>
      </div>

      <div class="pt-4" *ngIf="hasCardPresent" [formGroup]="processingVolumesForm">
        <tilled-input
          [merchantApp]="true"
          data-test-id="number-of-terminals"
          name="numberOfTerminals"
          groupName="processingVolumesForm"
          label="How many terminal readers do you plan on ordering?"
          tilledLabel="true"
          minValue="1"
          maxValue="10"
          mask="separator.0"
          placeholder=""
          [hint]="
            'This helps us setup your account properly. Estimates are okay here. This is not a terminal order. You will have a chance to order terminals later. Most businesses will start with 1 - 2 terminal readers.'
          "
          [errors]="
            processingVolumesForm.get('numberOfTerminals').touched &&
            (processingVolumesForm.get('numberOfTerminals').hasError('max') ||
              processingVolumesForm.get('numberOfTerminals').hasError('min'))
          "
          [errorMessage]="
            processingVolumesForm.get('numberOfTerminals').hasError('min')
              ? 'The number of terminal readers must be at least 1.'
              : 'The number of terminal readers must be less than 10.'
          "
        ></tilled-input>
      </div>
    </div>
    <!-- Average Transaction Amounts -->
    <div class="items-left flex flex-col pt-4" [ngClass]="!forConsole ? 'w-5/12' : 'w-full'">
      <tilled-heading-h4>Average Transaction Amounts</tilled-heading-h4>
      <tilled-paragraph-p3 [secondary]="true" class="pt-3">
        Accurately estimating your average transaction amounts is crucial to prevent delays in fund disbursement. If you
        process a wide range of transaction amounts, consider estimating your average on the higher end to avoid delays
        in fund disbursement.
      </tilled-paragraph-p3>
    </div>
    <div class="flex w-full flex-col" *ngIf="hasCard" [formGroup]="processingVolumesForm">
      <tilled-input
        [merchantApp]="true"
        data-test-id="average-transaction-amount-card"
        name="averageTransactionAmountCard"
        showCurrency="'true'"
        mask="separator.0"
        label="Credit Card - Average Transaction Amount"
        tilledLabel="true"
        hint="Estimate the typical amount for a single credit card sale or transaction. If you process a wide range of transaction amounts, consider estimating your average on the higher end to avoid delays in funds disbursement."
        [errors]="
          processingVolumesForm.get('averageTransactionAmountCard').touched &&
          (processingVolumesForm.get('averageTransactionAmountCard').hasError('max') ||
            processingVolumesForm.get('averageTransactionAmountCard').hasError('min'))
        "
        [errorMessage]="
          processingVolumesForm.get('averageTransactionAmountCard').hasError('min')
            ? 'Must be greater than $' + cardMin
            : processingVolumesForm.get('averageTransactionAmountCard').hasError('max')
              ? 'Must be less than $' + cardMax
              : 'Test'
        "
      ></tilled-input>
    </div>
    <div class="flex w-full flex-col" *ngIf="hasDebit" [formGroup]="processingVolumesForm">
      <tilled-input
        [merchantApp]="true"
        data-test-id="average-transaction-amount-debit"
        name="averageTransactionAmountDebit"
        showCurrency="'true'"
        mask="separator.0"
        label="Bank to Bank - Average Transaction Amount"
        tilledLabel="true"
        hint="Estimate the typical amount for a single bank to bank sale or transaction. If you process a wide range of transaction amounts, consider estimating your average on the higher end to avoid delays in funds disbursement."
        [errors]="
          processingVolumesForm.get('averageTransactionAmountDebit').touched &&
          (processingVolumesForm.get('averageTransactionAmountDebit').hasError('max') ||
            processingVolumesForm.get('averageTransactionAmountDebit').hasError('min'))
        "
        [errorMessage]="
          processingVolumesForm.get('averageTransactionAmountDebit').hasError('min')
            ? 'Must be greater than $' + debitMin
            : processingVolumesForm.get('averageTransactionAmountDebit').hasError('max')
              ? 'Must be less than $' + debitMax
              : 'Test'
        "
      ></tilled-input>
    </div>
  </form>
</merchant-app-card>
