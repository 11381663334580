import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ComponentBase } from 'app/core/componentBase';
import { TilledParagraphP3Component } from 'app/shared/tilled-text/tilled-paragraph/tilled-paragraph-p3.component';
import { TilledButtonComponent } from '../../../buttons/tilled-button.component';
import { TilledFormHeadingComponent } from '../../../tilled-text/tilled-heading/tilled-form-heading';
import { TilledHeadingH3Component } from '../../../tilled-text/tilled-heading/tilled-heading-h3.component';

@Component({
  selector: 'merchant-app-card',
  templateUrl: './merchant-app-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    TilledHeadingH3Component,
    TilledFormHeadingComponent,
    TilledParagraphP3Component,
    TilledButtonComponent,
    CommonModule,
  ],
})
export class MerchantAppCardComponent extends ComponentBase implements OnInit, AfterViewInit {
  @Output() backClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() continueClick: EventEmitter<string> = new EventEmitter<string>();

  @Input() backButton?: boolean = true;
  @Input() continueButton?: boolean = true;
  @Input() continueButtonText?: string = 'Save and Continue';
  @Input() continueButtonIcon?: string;
  @Input() disableContinueButton?: boolean = false;

  @Input() title?: string;
  @Input() description?: string;
  @Input() bypassHalfWidth: boolean = false;
  public windowWidth: any;
  public tilledInputWidth: any;
  public forConsole: boolean = false;

  constructor() {
    super();
  }
  @HostListener('window:resize', ['$event'])
  @HostListener('document:mousemove', ['$event'])
  public onResize(event: any): void {
    this.windowWidth = window.innerWidth;
    this.tilledInputWidth = this.getElementWidth('tilled-input mat-form-field');
  }

  ngOnInit(): void {
    this.windowWidth = window.innerWidth;
    if (document.querySelector('app-account-details')) {
      this.forConsole = true;
    }
  }

  ngAfterViewInit(): void {
    document.dispatchEvent(new MouseEvent('mousemove'));
  }

  public getElementWidth(selector: string): string {
    let element = document?.querySelector(selector) || document?.querySelector('tilled-select mat-form-field');
    if (!element) {
      return '100%';
    }
    return element.clientWidth + 'px';
  }

  onButtonClick(text): void {
    switch (text) {
      case 'back':
        this.backClick.emit();
        break;
      case 'continue':
        this.continueClick.emit();
        break;
    }
  }
}
