import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TilledSelectComponent } from 'app/shared/tilled-select/tilled-select.component';
import { NgxMaskModule } from 'ngx-mask';
import { SentenceCasePipe } from '../../../core/pipes/sentence-case.pipe';
import { AllowOnlyNumbersDirective } from '../../allow-only-numbers.directive';
import { TilledLabelL1Component } from '../../tilled-text/tilled-label/tilled-label-l1.component';
import { TilledParagraphP4Component } from '../../tilled-text/tilled-paragraph/tilled-paragraph-p4.component';

@Component({
  selector: 'tilled-input',
  templateUrl: './tilled-input.component.html',
  styleUrls: ['../form-fields.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { floatLabel: 'always' },
    },
  ],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    TilledLabelL1Component,
    MatFormFieldModule,
    MatInputModule,
    NgxMaskModule,
    AllowOnlyNumbersDirective,
    TilledSelectComponent,
    MatIconModule,
    MatTooltipModule,
    TilledParagraphP4Component,
    SentenceCasePipe,
    CommonModule,
  ],
})
export class TilledInputComponent implements OnInit {
  @Output() copyClick: EventEmitter<string> = new EventEmitter<string>();
  @Input() label?: string;
  @Input() placeholder?: string;
  @Input() name?: string;
  @Input() control?: FormControl;
  @Input() groupName?: string;
  @Input() fieldType?: string = 'text';
  @Input() styles?: string = '';
  @Input() showPercent?: boolean;
  @Input() showCurrency?: boolean;
  @Input() mask?: string;
  @Input() dropMaskCharacters?: boolean = true;
  @Input() leadZeroDateTime?: boolean = false;
  @Input() thousandSeparator?: string = ',';
  @Input() readonly?: boolean = false;
  @Input() errors?: boolean = false;
  @Input() errorMessage?: string;
  @Input() hideRequiredMarker?: boolean = false;
  @Input() minLength?: number;
  @Input() maxLength?: number = 255;
  @Input() minValue?: number;
  @Input() maxValue?: number;
  @Input() appearance?: 'fill' | 'outline' = 'fill';
  @Input() tilledLabel: boolean = false;
  @Input() sentenceCaseTitle?: boolean = true;
  @Input() sentenceCasePlaceholder?: boolean = true;
  @Input() toolTip: string = null;
  @Input() required: boolean = false;
  @Input() showClear?: boolean = false;
  @Input() hint?: string = null;
  @Input() prefix: string;
  @Input() suffix: string;
  @Input() prefixSuffixBorder?: 'prefix' | 'suffix' | 'both';
  @Input() prefixMultiple?: boolean = true;
  @Input() suffixMultiple?: boolean = true;
  @Input() prefixOptions?: { value: string; viewValue: string }[];
  @Input() suffixOptions?: { value: string; viewValue: string }[];
  @Input() prefixPlaceholder?: string;
  @Input() suffixPlaceholder?: string;
  @Input() suffixControlName?: FormControl;
  @Input() prefixControlName?: FormControl;
  @Input() suffixReadonly?: boolean;
  @Input() copyToClipboard?: boolean = false;
  @Input() copyClickText?: string = 'Generate';
  @Input() merchantApp?: boolean;
  @Output() onBlur: EventEmitter<string> = new EventEmitter<string>();
  @Output() onFocus: EventEmitter<string> = new EventEmitter<string>();

  public windowWidth: any;
  public isFocused: boolean = false;

  constructor() {}

  @HostListener('window:resize', ['$event'])
  public onResize(event: any): void {
    this.windowWidth = window.innerWidth;
  }
  ngOnInit() {
    this.windowWidth = window.innerWidth;
    if (this.merchantApp) {
      this.merchantApp = document.querySelector('app-account-details') ? false : true;
    }
  }

  onInputBlur() {
    this.onBlur.emit(this.name);
  }

  onInputFocus() {
    this.onFocus.emit(this.name);
    this.isFocused = true;
  }

  onCopyClick(text) {
    this.copyClick.emit(text);
  }
}
