<div class="top-of-form"></div>
<merchant-app-card
  [title]="!forConsole ? 'Add bank account' : null"
  [description]="!forConsole ? 'Enter the bank account information where funds will be deposited and debited.' : null"
  [backButton]="!forConsole"
  (backClick)="onBackClicked($event)"
  (continueClick)="onContinueClicked($event)"
  [continueButton]="!forConsole && !(disabled$ | async)"
>
  <form data-test-id="bank-account-step" class="w-full" [formGroup]="bankAccountForm">
    <!-- used for hidden element to define button width -->
    <form *ngIf="hasPlaid && !showBankAccountForm" [formGroup]="hiddenForm" class="invisible -mb-13 w-1/2 opacity-0">
      <tilled-input name="hidden" class="pointer-events-none flex"> </tilled-input>
    </form>
    <!-- Bank Account Info Alert -mx-6 -mt-2 -->
    <div [class]="!forConsole ? 'mr-8' : ''">
      <fuse-alert
        data-test-id="bankAccountAlert"
        [type]="'basic'"
        [appearance]="'outline'"
        [dismissible]="false"
        [dismissed]="false"
        [name]="'bankAccountAlertBox'"
        [class]="!forConsole ? 'w-1/2' : ''"
      >
        <mat-icon class="icon-size-5" fuseAlertIcon [svgIcon]="'mat_outline:help_outline'"> </mat-icon>
        <tilled-heading-h6 fuseAlertTitle>Bank Account Requirements</tilled-heading-h6>
        <div class="flex flex-row">
          &bull;&nbsp;<tilled-paragraph-p3
            >Your bank account must be registered with the same legal name or DBA as your merchant
            account.</tilled-paragraph-p3
          >
        </div>
        <div class="flex flex-row">
          &bull;&nbsp;<tilled-paragraph-p3>
            For sole proprietorships, your bank account must be registered under the same Beneficial Owner as your
            merchant account.</tilled-paragraph-p3
          >
        </div>

        <div class="flex flex-row">
          &bull;&nbsp;<tilled-paragraph-p3
            >Your bank account must be able to receive both ACH credits and debits.</tilled-paragraph-p3
          >
        </div>
      </fuse-alert>
    </div>

    <div *ngIf="isLoading" class="m-44 flex flex-col items-center justify-center">
      <div *ngIf="falconProcessing" class="m-6">Preparing your data on our super secure servers.</div>
      <mat-spinner diameter="50"></mat-spinner>
    </div>

    <div [hidden]="isLoading" [ngClass]="{ 'flex w-1/2 flex-col': hasPlaid && !showBankAccountForm }">
      <div *ngIf="showManualUpload && hasPlaid" class="flex flex-row items-center space-x-2 pt-6">
        <button mat-button class="rounded-3xl bg-black text-white" (click)="connectWithPlaidInstead()">
          Connect with Plaid instead
        </button>

        <tilled-paragraph-p3 class="">What is Plaid?</tilled-paragraph-p3>
        <mat-icon
          class="text-black icon-size-5"
          [svgIcon]="'mat_outline:help_outline'"
          matTooltip="Plaid is a technology that securely connects your bank account to third-party services using bank-level 256-bit encryption. It's important to note that your credentials are never shared with the third-party app or service, including our own. This helps ensure that your personal and financial data is kept safe and secure."
        ></mat-icon>
      </div>
      <div *ngIf="!showBankAccountForm" class="flex flex-col items-center">
        <div class="flex flex-col items-center space-y-6 pb-12 pt-6">
          <img class="w-24" src="/assets/icons/piggy_bank_icon.svg" />
          <tilled-heading-h4 class="text">Bank Account Verification</tilled-heading-h4>
          <div class="flex flex-row items-center space-x-2">
            <tilled-paragraph-p3 class="">Connect you bank account with Plaid to receive deposits.</tilled-paragraph-p3>
            <mat-icon
              class="text-black icon-size-5"
              [svgIcon]="'mat_outline:help_outline'"
              matTooltip="Plaid is a technology that securely connects your bank account to third-party services using bank-level 256-bit encryption. It's important to note that your credentials are never shared with the third-party app or service, including our own. This helps ensure that your personal and financial data is kept safe and secure."
            ></mat-icon>
          </div>

          <div class="flex flex-row items-center space-x-2">
            <mat-icon class="text-black" [svgIcon]="'heroicons_solid:lock-closed'"> </mat-icon>
            <tilled-paragraph-p3>We secure your information with bank-level 256-bit encryption. </tilled-paragraph-p3>
          </div>

          <tilled-button
            bgColor="tilled-primary"
            buttonText="Connect Account"
            (buttonClick)="getLinkToken()"
          ></tilled-button>
        </div>

        <button (click)="showManualVerification()">
          <tilled-paragraph-p3 [primaryHighlight]="true" class="text-primary underline">
            Connect manually with account numbers.
          </tilled-paragraph-p3>
        </button>
        <tilled-paragraph-p4
          >A Bank Letter or Voided Check is required for underwriting to manually verify the bank
          account.</tilled-paragraph-p4
        >
      </div>

      <div *ngIf="verifiedByPlaid" class="py-4">
        <tilled-paragraph-p2 bold="true"> This account has been verified.</tilled-paragraph-p2>
        <button (click)="deletePlaidVerifiedAccount()">
          <tilled-paragraph-p4 [primaryHighlight]="true">
            Connect manually with account numbers instead.
          </tilled-paragraph-p4>
        </button>
      </div>
      <!-- Bank Account Details -->
      <div *ngIf="showBankAccountForm" class="grid w-full grid-cols-1">
        <!-- first row -->
        <div class="col-span-1 flex flex-col pt-5">
          <div class="w-full pb-2.5 pt-4">
            <tilled-label-l1 [secondary]="true"> Account type </tilled-label-l1>
          </div>
          <tilled-select
            [merchantApp]="true"
            [multiple]="false"
            placeholder="Select an account type"
            [controlName]="bankAccountForm.get('accountType')"
            data-test-id="account-type"
            [classOverride]="forConsole ? 'w-full' : ''"
            [options]="[
              { value: 'checking', label: 'Checking' },
              { value: 'savings', label: 'Savings' }
            ]"
          ></tilled-select>
        </div>
        <!-- second row -->
        <div class="col-span-1 flex flex-col">
          <tilled-input
            [merchantApp]="true"
            data-test-id="account-holder"
            class="w-full"
            name="accountHolder"
            label="Account Holder or Business Name"
            tilledLabel="true"
            placeholder="Enter the name on the account"
          ></tilled-input>
        </div>
        <div class="col-span-1 flex flex-col">
          <tilled-input
            [merchantApp]="true"
            data-test-id="bank-name"
            class="w-full"
            name="bankName"
            label="Bank Name"
            tilledLabel="true"
            placeholder="Enter the name of the bank"
          ></tilled-input>
        </div>
        <!-- third row -->
        <div class="col-span-1 flex flex-col">
          <tilled-input
            [merchantApp]="true"
            data-test-id="account-number"
            class="w-full"
            name="accountNumber"
            label="Account Number"
            tilledLabel="true"
            placeholder="Enter the account number"
            maxLength="20"
          ></tilled-input>
        </div>
        <div class="col-span-1 flex flex-col">
          <tilled-input
            [merchantApp]="true"
            data-test-id="routing-number"
            class="w-full"
            name="routingNumber"
            placeholder="Enter the routing number"
            [label]="routingNumberLabel"
            tilledLabel="true"
            maxLength="9"
            [errors]="
              bankAccountForm.get('routingNumber').hasError('invalidRoutingNumber') &&
              bankAccountForm.get('routingNumber').touched
            "
            errorMessage="{{ routingNumberLabel }} is invalid."
          ></tilled-input>
        </div>
      </div>
    </div>
    <div *ngIf="showManualUpload">
      <div class="flex flex-row items-center space-x-2 pt-10">
        <tilled-paragraph-p2 bold="true"> Upload a Voided Check or Bank Letter </tilled-paragraph-p2>
        <mat-icon
          class="text-black icon-size-5"
          [svgIcon]="'mat_outline:help_outline'"
          matTooltipClass="tooltip-list"
          [matTooltip]="bankLetterTooltipText"
        ></mat-icon>
      </div>

      <app-file-upload
        class="-ml-1 pt-8"
        [ngClass]="{ '*:w-1/2': !forConsole, '*:w-full': forConsole }"
        [fileCountLimit]="2"
        [isMerchantApp]="true"
        [showOverlay]="false"
        [purpose]="filePurpose"
        [allowedFileTypes]="fileTypes"
        [fileDescriptions]="fileDescriptions"
        [existingFiles$]="existingFiles$"
        (fileDeleted)="fileDeleted($event)"
        (pendingFilesChange)="pendingFilesChange($event)"
        [requiredFileDescriptions]="['Voided Check', 'Bank Letter']"
        (requiredFilesUploaded)="requiredFilesUploaded($event)"
        [fileAccountId]="fileAccountId"
      ></app-file-upload>
    </div>
  </form>
</merchant-app-card>
