<div class="top-of-form"></div>
<merchant-app-card
  [title]="!forConsole ? 'Business Details' : null"
  [description]="!forConsole ? 'Tell us about your business' : null"
  [backButton]="false"
  (continueClick)="onContinueClicked($event)"
  [continueButton]="!forConsole && !(disabled$ | async)"
>
  <form data-test-id="details-step" class="w-full" [formGroup]="businessDetailsForm">
    <tilled-input
      [merchantApp]="true"
      data-test-id="details-legal-name"
      class="w-full"
      name="legalName"
      placeholder="Full Legal Business Name"
      label="LEGAL BUSINESS NAME"
      [hint]="
        businessDetailsForm.get('type').value === 'sole_proprietorship'
          ? 'Enter your full personal name here—it\'s your Sole Proprietorship\'s legal business name. You\'ll have a chance to add a \'Doing Business As\' (DBA) name later on.'
          : 'Enter your legal business name exactly as it appears on your IRS issued documents.'
      "
      tilledLabel="true"
    ></tilled-input>
    <tilled-input
      [merchantApp]="true"
      data-test-id="details-dba"
      class="w-full"
      name="dba"
      placeholder="Business “DBA” or Trading Name"
      label="Doing business as (optional)"
      tilledLabel="true"
      [hint]="'Enter the business name you present to customers, if it\'s different from your legal name.'"
      [sentenceCaseTitle]="false"
    ></tilled-input>
    <div class="col-span-1 flex w-full flex-col">
      <div class="w-full pb-2.5 pt-4">
        <tilled-label-l1 class="w-full" secondary="true">Business type</tilled-label-l1>
      </div>
      <tilled-select
        [classOverride]="forConsole ? 'w-full' : ''"
        [merchantApp]="true"
        data-test-id="details-entity-type"
        [multiple]="false"
        [options]="entityTypesOptions"
        [controlName]="businessDetailsForm.get('type')"
        [placeholder]="'Choose One'"
        [errors]="businessDetailsForm.get('type').hasError('required') && businessDetailsForm.get('type').touched"
        errorMessage="A business entity type is required"
        [hint]="'Choose your legal business structure as registered for tax filing.'"
      ></tilled-select>
    </div>
    <tilled-input
      [merchantApp]="true"
      data-test-id="details-business-identifier"
      class="w-full"
      name="businessIdentifier"
      placeholder="Tax ID #, Canadian Business #, Social Security # or EIN"
      label="BUSINESS IDENTIFIER"
      tilledLabel="true"
      mask="000000000"
      [errors]="
        businessDetailsForm.get('businessIdentifier').invalid && businessDetailsForm.get('businessIdentifier').touched
      "
      errorMessage="Business Identifier must be 9 digits."
      [hint]="
        businessDetailsForm.get('type').value === 'sole_proprietorship'
          ? 'As a Sole Proprietorship, if you use your Social Security Number (SSN) for business tax purposes, you can enter that instead. We are required to collect this information to satisfy regulatory obligations.'
          : 'Enter your Tax ID / EIN exactly as it appears on your IRS issued documents.'
      "
    ></tilled-input>

    <tilled-input
      [merchantApp]="true"
      data-test-id="details-date-of-inc"
      name="dateOfInc"
      label="Date of incorporation"
      placeholder="MM/DD/YYYY"
      [sentenceCasePlaceholder]="false"
      tilledLabel="true"
      mask="M0/d0/0000"
      [dropMaskCharacters]="false"
      [leadZeroDateTime]="true"
      [errors]="businessDetailsForm.get('dateOfInc').errors?.['mask']"
      errorMessage="Date of incorporation must be a valid date."
    ></tilled-input>

    <div class="col-span-1 flex flex-col">
      <div class="w-full pb-2.5 pt-4">
        <tilled-label-l1 secondary="true">Industry</tilled-label-l1>
      </div>
      <tilled-select
        [merchantApp]="true"
        [classOverride]="forConsole ? 'w-full' : ''"
        *ngIf="mccsLoaded$ | async"
        data-test-id="details-business-category"
        [multiple]="false"
        [options]="mccCodeMap"
        [controlName]="businessDetailsForm.get('category')"
        [placeholder]="'Search by industry name'"
        [errors]="
          businessDetailsForm.get('category').hasError('required') && businessDetailsForm.get('category').touched
        "
        errorMessage="A business category is required"
        [hint]="'Select the option that best matches the goods or service your customers will buy.'"
      ></tilled-select>
    </div>
    <tilled-input
      [merchantApp]="true"
      data-test-id="details-b2b-percent"
      name="b2bPercent"
      label="What percent of your company's sales are directly to other businesses (B2B)?"
      tilledLabel="true"
      placeholder="Enter %"
      [showPercent]="true"
      [sentenceCaseTitle]="false"
      minValue="0"
      maxValue="100"
      mask="percent"
      [errors]="
        businessDetailsForm.get('b2bPercent').errors?.['min'] ||
        (businessDetailsForm.get('b2bPercent').errors?.['max'] && businessDetailsForm.get('b2bPercent').touched)
      "
      [errorMessage]="'Must not be greater than 100%'"
      [hint]="'Estimates are okay here.'"
    ></tilled-input>
    <tilled-input
      [merchantApp]="true"
      data-test-id="details-statement-descriptor"
      class="flex w-full pb-2.5"
      name="statementDescriptor"
      placeholder="Enter your statement descriptor"
      label="Statement Descriptor"
      maxLength="20"
      tilledLabel="true"
      [hint]="
        'Enter how you want your business name to appear on your customers\' credit card and bank statements. Limit of 20 characters.'
      "
    ></tilled-input>
    <div class="input-container mt-4 flex w-full flex-row items-baseline">
      <div
        class="field-container flex flex-col"
        [attr.style]="
          windowWidth >= 1280 && !forConsole
            ? 'width: 50vw'
            : !forConsole && windowWidth >= 1280
              ? 'width: 90vw'
              : 'width: 100%'
        "
      >
        <div class="w-full pb-2.5">
          <tilled-label-l1>Describe your product or services</tilled-label-l1>
        </div>
        <mat-form-field
          appearance="fill"
          class="mr-8 flex overflow-visible"
          [ngClass]="{
            'w-11/12': windowWidth >= 1280
          }"
        >
          <textarea
            class="text-field"
            data-test-id="details-description"
            [formControlName]="'description'"
            matInput
            maxLength="240"
            cdkTextareaAutosize
            cdkAutosizeMinRows="3"
            cdkAutosizeMaxRows="5"
            placeholder="We're a local food truck that sells donuts and coffee."
            (focus)="isFocused = true"
            (focusout)="isFocused = false"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="mr-6 mt-10 flex self-center" *ngIf="windowWidth > 767" [ngClass]="{ '': windowWidth <= 1280 }">
        <div
          class="tooltipSideDiv flex py-2"
          [ngClass]="{
            'w-[35vw] pl-12': windowWidth <= 767,
            'w-[32vw] pr-5': windowWidth > 767
          }"
        >
          <mat-hint *ngIf="isFocused" class="pt-1 font-openSans text-base leading-5 text-tilled-primary"
            >Provide a 1-2 sentence description.</mat-hint
          >
        </div>
      </div>
    </div>
  </form>
</merchant-app-card>
