<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<div class="fixed left-0 right-0 top-20 z-[99999] m-auto w-full max-w-full px-6 sm:w-fit sm:max-w-[50%] sm:px-0">
  <tilled-alert></tilled-alert>
</div>

<!-- Wrapper -->
<div class="flex w-full flex-auto flex-col">
  <div class="z-50 flex flex-row items-center justify-between bg-white px-0 py-4 shadow-md drop-shadow tilled-md:px-10">
    <div
      class="relative left-[8px] mr-4 flex items-center"
      [ngClass]="{ 'ml-4': windowWidth < 767 }"
      *ngIf="(!isMultiAccount && windowWidth > 540) || (isMultiAccount && windowWidth > 767)"
    >
      <img class="mr-8 max-h-40 w-40" src="{{ logoUrl$ | async }}" />
    </div>
    <div
      class="flex grow flex-row md:justify-end"
      [ngClass]="{
        'justify-center': isMultiAccount || windowWidth < 540,
        'justify-end': !isMultiAccount && windowWidth > 540
      }"
    >
      <!-- Header action items -->
      <div
        class="flex items-center justify-end gap-y-6 md:mx-0"
        [ngClass]="{
          'w-full': isMultiAccount,
          'flex-col-reverse md:flex-row': windowWidth <= 770 && isMultiAccount,
          'flex-row': !isMultiAccount || windowWidth > 770
        }"
      >
        <div
          *ngIf="windowWidth <= 770 && !isMultiAccount"
          class="ml-2 mr-4 flex h-14 items-center self-center border-r border-tilled-neutral-300 pr-2"
        >
          <button
            data-test-id="open-steps-btn"
            mat-icon-button
            [matMenuTriggerFor]="
              (windowWidth < 480 && isMultiAccount) || (windowWidth < 420 && !isMultiAccount)
                ? helpAndDrawerToggle
                : null
            "
            (click)="
              (windowWidth > 480 && isMultiAccount) || (windowWidth > 420 && !isMultiAccount) ? stepperToggle() : null
            "
          >
            <mat-icon [svgIcon]="'heroicons_outline:bars-2'" class="text-tilled-primary"> </mat-icon>
          </button>
        </div>
        <div
          *ngIf="windowWidth < 1280 && windowWidth > 770"
          class="ml-2 mr-4 flex h-14 items-center self-center border-r border-tilled-neutral-300 pr-2"
        >
          <button
            data-test-id="open-steps-btn"
            mat-icon-button
            [matMenuTriggerFor]="windowWidth < 480 ? helpAndDrawerToggle : null"
            (click)="windowWidth > 480 ? stepperToggle() : null"
          >
            <mat-icon [svgIcon]="'heroicons_outline:bars-2'" class="text-tilled-primary"> </mat-icon>
          </button>
        </div>
        <!-- Invite user -->
        <div
          data-test-id="merchant-app-drawer-user-invite"
          class="invite-wrapper flex items-center self-center rounded-full md:w-fit"
          [ngClass]="{
            'tilled-gradient': !(isWhiteLabel$ | async),
            'w-11/12': isMultiAccount
          }"
        >
          <div
            class="invite-btn z-50 m-px my-px flex max-h-10 w-full cursor-pointer flex-row items-center justify-center rounded-full bg-white px-12 py-2"
            [ngClass]="{
              'border-[1.5px] border-accent': (isWhiteLabel$ | async),
              'm-px': !(isWhiteLabel$ | async)
            }"
            (click)="inviteUser()"
          >
            <tilled-paragraph-p3 [bold]="true" class="mr-1.5 whitespace-nowrap text-tilled-primary">
              Invite collaborators
            </tilled-paragraph-p3>
            <mat-icon class="text-tilled-primary">
              <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M18.3337 16.5V14.8333C18.3337 13.2801 17.2713 11.9751 15.8337 11.605M12.917 1.7423C14.1386 2.23679 15.0003 3.43443 15.0003 4.83333C15.0003 6.23224 14.1386 7.42988 12.917 7.92437M14.167 16.5C14.167 14.9469 14.167 14.1703 13.9133 13.5577C13.5749 12.741 12.926 12.092 12.1093 11.7537C11.4967 11.5 10.7201 11.5 9.16699 11.5H6.66699C5.11385 11.5 4.33728 11.5 3.72471 11.7537C2.90795 12.092 2.25904 12.741 1.92073 13.5577C1.66699 14.1703 1.66699 14.9469 1.66699 16.5M11.2503 4.83333C11.2503 6.67428 9.75794 8.16667 7.91699 8.16667C6.07604 8.16667 4.58366 6.67428 4.58366 4.83333C4.58366 2.99238 6.07604 1.5 7.91699 1.5C9.75794 1.5 11.2503 2.99238 11.2503 4.83333Z"
                  stroke="#1B253B"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </mat-icon>
          </div>
        </div>
        <div
          *ngIf="!isMultiAccount && windowWidth > 420"
          class="h-14 self-center border-r border-tilled-neutral-300 pr-5"
        ></div>
        <!-- Multi-account selector, User menu, and help -->
        <div class="flex">
          <div
            class="relative left-[8px] mx-4 flex items-center"
            *ngIf="isMultiAccount && windowWidth < 767 && windowWidth > 540"
          >
            <img class="max-h-40 w-40" src="{{ logoUrl$ | async }}" />
          </div>
          <div
            *ngIf="windowWidth <= 770 && isMultiAccount"
            class="ml-2 flex h-14 items-center self-center border-r border-tilled-neutral-300 pr-2"
          >
            <button
              data-test-id="open-steps-btn"
              mat-icon-button
              [matMenuTriggerFor]="windowWidth < 480 ? helpAndDrawerToggle : null"
              (click)="windowWidth > 480 ? stepperToggle() : null"
            >
              <mat-icon [svgIcon]="'heroicons_outline:bars-2'" class="text-tilled-primary"> </mat-icon>
            </button>
          </div>
          <!-- Toggle drawer, user, and help menu for mobile -->
          <mat-menu [xPosition]="'before'" #helpAndDrawerToggle>
            <button
              mat-menu-item
              data-test-id="user-menu"
              class="min-h-7 py-2"
              #userMenuTrigger="matMenuTrigger"
              [matMenuTriggerFor]="userMenu"
            >
              <mat-icon
                class="icon-size-5"
                [ngClass]="(isWhiteLabel$ | async) ? 'text-tilled-primary' : 'text-black'"
                [svgIcon]="'heroicons_outline:user-circle'"
              ></mat-icon>
              <div class="flex flex-row items-center">
                <div class="w-52 overflow-hidden text-ellipsis whitespace-nowrap">
                  <span class="font-openSans not-italic leading-normal tracking-normal text-tilled-primary"
                    >Signed in as
                    <span
                      class="font-openSans font-semibold not-italic leading-normal tracking-normal text-tilled-primary"
                      >{{ user.email }}
                    </span></span
                  >
                </div>
                <mat-icon
                  class="icon-size-4"
                  [ngClass]="(isWhiteLabel$ | async) ? 'text-tilled-primary' : 'text-black'"
                  [svgIcon]="'heroicons_outline:chevron-right'"
                ></mat-icon>
              </div>
            </button>
            <mat-divider class="mx-auto mb-0 mt-0 w-10/12"></mat-divider>
            <button mat-menu-item class="min-h-7 py-2" (click)="stepperToggle()">
              <mat-icon
                class="icon-size-5"
                [ngClass]="(isWhiteLabel$ | async) ? 'text-tilled-primary' : 'text-black'"
                [svgIcon]="'heroicons_outline:bars-2'"
              ></mat-icon>
              <tilled-paragraph-p3 [bold]="true" class="text-tilled-primary">
                {{ (drawerOpened$ | async) ? 'Close steps' : 'Open steps' }}
              </tilled-paragraph-p3>
            </button>
            <a mat-menu-item class="min-h-7 py-2" href="{{ merchantSupportCenterUrl }}" target="_blank">
              <mat-icon
                class="icon-size-5"
                [ngClass]="(isWhiteLabel$ | async) ? 'text-tilled-primary' : 'text-black'"
                [svgIcon]="'heroicons_outline:question-mark-circle'"
              ></mat-icon>
              <tilled-paragraph-p3 [bold]="true" class="text-tilled-primary"
                >Visit our merchant help center</tilled-paragraph-p3
              >
            </a>
          </mat-menu>

          <mat-menu [xPosition]="'before'" #userMenu>
            <a
              data-test-id="reset-password-button"
              mat-menu-item
              class="-mt-1 min-h-7"
              href="/forgot-password"
              target="_self"
            >
              <mat-icon
                class="icon-size-5"
                [ngClass]="(isWhiteLabel$ | async) ? 'text-tilled-primary' : 'text-black'"
                [svgIcon]="'heroicons_outline:arrow-path'"
              ></mat-icon>
              <tilled-paragraph-p3 [bold]="true" class="whitespace-nowrap text-tilled-primary"
                >Reset password</tilled-paragraph-p3
              >
            </a>
            <a data-test-id="sign-out-button" mat-menu-item class="min-h-7" href="/sign-out" target="_self">
              <mat-icon
                class="icon-size-5"
                [ngClass]="(isWhiteLabel$ | async) ? 'text-tilled-primary' : 'text-black'"
                [svgIcon]="'mat_outline:logout'"
              >
              </mat-icon>
              <tilled-paragraph-p3 [bold]="true" class="whitespace-nowrap text-tilled-primary"
                >Sign out</tilled-paragraph-p3
              >
            </a>
          </mat-menu>
          <!-- Multi-account selector -->
          <app-multi-account-selector
            *ngIf="this.isMultiAccount"
            class="h-14 self-center border-tilled-neutral-300 px-6"
            [ngClass]="{
              'tilled-gradient': !(isWhiteLabel$ | async),
              'mr-1 border-r': windowWidth > 480
            }"
          ></app-multi-account-selector>
          <!-- Help and User menus -->
          <div
            class="ml-2 flex flex-row"
            *ngIf="(isMultiAccount && windowWidth > 480) || (!isMultiAccount && windowWidth > 420)"
            [ngClass]="{ 'relative left-1.5': windowWidth < 767, 'flex-wrap': windowWidth < 450 }"
          >
            <button data-test-id="open-help-menu" class="self-center" mat-icon-button [matMenuTriggerFor]="helpCenter">
              <mat-icon
                [class]="(isWhiteLabel$ | async) ? 'text-tilled-primary' : 'text-black'"
                [svgIcon]="'heroicons_outline:question-mark-circle'"
              ></mat-icon>
            </button>

            <mat-menu [xPosition]="'before'" #helpCenter="matMenu">
              <a mat-menu-item class="min-h-7" href="{{ merchantSupportCenterUrl }}" target="_blank">
                <mat-icon
                  class="icon-size-5"
                  [ngClass]="(isWhiteLabel$ | async) ? 'text-tilled-primary' : 'text-black'"
                  [svgIcon]="'heroicons_outline:question-mark-circle'"
                ></mat-icon>
                <div class="flex whitespace-pre-wrap">
                  <tilled-paragraph-p3 [bold]="true" class="text-tilled-primary"
                    >Visit our merchant help center</tilled-paragraph-p3
                  >
                </div>
              </a>
            </mat-menu>
            <!-- User Profile/Logout -->
            <user
              [whiteLabel]="isWhiteLabel$ | async"
              class="self-center"
              [ngClass]="{ 'mr-3': windowWidth < 767, '-mr-1': windowWidth > 767 }"
            ></user>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Content -->
  <mat-divider class="onboarding-divider w-full"></mat-divider>
  <div class="flex flex-auto flex-col">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
    Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>
</div>
