<span [matTooltip]="disabledTooltip" [matTooltipDisabled]="!disabled">
  <button
    class="bg-{{ backgroundColor }} bg-opacity-{{ bgOpacity }} text-{{ textDisplayColor }} min-w-0 {{ styles }} {{
      outlineColor ? 'outline -outline-offset-2 outline-' + outlineColor : ''
    }}"
    [ngClass]="{ 'rounded-sm': !rounded, 'rounded-3xl': rounded }"
    mat-flat-button
    (click)="onButtonClick()"
    [disabled]="disabled"
  >
    <tilled-button-b2 [white]="displayWhite" [secondary]="secondary" styles="flex">
      <mat-icon class="mr-1 icon-size-5" *ngIf="prefixIcon" [svgIcon]="prefixIcon"></mat-icon>
      {{ buttonText }}
      <mat-icon *ngIf="suffixIcon" class="ml-1 icon-size-5" [svgIcon]="suffixIcon"></mat-icon>
    </tilled-button-b2>
  </button>
</span>
