<ng-container *ngIf="!merchantApp">
  <mat-form-field appearance="fill" class="w-full overflow-visible">
    <mat-label *ngIf="!tilledLabel" class="text-xl {{ styles }}">{{ label }}</mat-label>
    <div *ngIf="tilledLabel" class="mb-4">
      <tilled-label-l1 [secondary]="true">{{ label }}</tilled-label-l1>
    </div>
    <input
      (focus)="isFocused = true"
      (focusout)="isFocused = false"
      type="{{ fieldType }}"
      [formControlName]="name"
      matInput
      placeholder="{{ placeholder }}"
      [value]="streetWithNumber"
      #addresstext
    />
    <mat-hint id="field-hint" class="mb-0.5 pt-1" *ngIf="hint">{{ hint }}</mat-hint>
  </mat-form-field>
</ng-container>

<ng-container *ngIf="merchantApp">
  <div class="input-container flex w-full flex-row items-end">
    <div class="field-container flex flex-col" [attr.style]="windowWidth >= 1280 ? 'width: 50vw' : 'width: 90vw'">
      <mat-form-field
        appearance="fill"
        class="mr-8 flex overflow-visible"
        [ngClass]="{
          'flex w-11/12': windowWidth >= 1280
        }"
      >
        <mat-label *ngIf="!tilledLabel" class="text-xl {{ styles }}">{{ label }}</mat-label>
        <div *ngIf="tilledLabel" class="mb-4">
          <tilled-label-l1 [secondary]="true">{{ label }}</tilled-label-l1>
        </div>
        <input
          (focus)="isFocused = true"
          (focusout)="isFocused = false"
          type="{{ fieldType }}"
          [formControlName]="name"
          matInput
          placeholder="{{ placeholder }}"
          [value]="streetWithNumber"
          #addresstext
        />
        <mat-hint id="field-hint" class="mb-0.5 pt-1" *ngIf="hint && windowWidth <= 767 && isFocused">{{
          hint
        }}</mat-hint>
      </mat-form-field>
    </div>
    <div class="mr-6 flex h-13 items-center" *ngIf="windowWidth > 767" [ngClass]="{ '': windowWidth <= 1280 }">
      <div
        class="tooltipSideDiv flex"
        [ngClass]="{
          'w-[35vw] pl-12': windowWidth <= 767,
          'w-[32vw] pr-5': windowWidth > 767
        }"
      >
        <mat-hint *ngIf="hint && isFocused" class="pt-1 font-openSans text-base leading-5 text-tilled-primary">{{
          hint
        }}</mat-hint>
      </div>
    </div>
  </div>
</ng-container>
